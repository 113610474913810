<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Body-->
    <div class="card-body pt-4 d-flex flex-column justify-content-between">
      <!--begin::User-->
      <div class="d-flex justify-content-between align-items-center mb-7">
        <!--begin::Title-->

        <p class="text-dark font-weight-bold font-size-h4 mb-0">
          {{ driver.name }}
        </p>
        <p class="text-dark mb-0">
          <b-badge class="mr-1" :variant="driver.status == 'ACTIVE' ? 'success' : 'danger'">{{
            driver.status
          }}</b-badge>
        </p>
        <!--end::Title-->
      </div>
      <!--end::User-->
      <!--begin::Info-->
      <div class="mb-7">
        <div class="d-flex justify-content-between align-items-cente my-1">
          <span class="text-dark-75 font-weight-bolder mr-2">Phone:</span>
          <span class="text-muted font-weight-bold">{{ driver.username }}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-dark-75 font-weight-bolder mr-2">Vehicle Number:</span>
          <span class="text-muted font-weight-bold">{{ driver.vehicle_no }}</span>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between flex-wrap py-3">
        <div class="d-flex align-items-center mr-2 py-2">
          <router-link
            :to="{
              name: 'UpdateDriver',
              params: { id: driver.id, driverObject: driver }
            }"
            class="btn btn-icon btn-primary mx-3"
          >
            <i class="flaticon2-pen"></i>
          </router-link>
        </div>

        <div
          class="btn btn-icon mx-3"
          :class="driver.status == 'ACTIVE' ? 'btn-danger' : 'btn-warning'"
          @click="$emit('delete-driver', driver.id, driver.status)"
        >
          <i class="la" :class="driver.status == 'ACTIVE' ? 'la-lock' : 'la-lock-open'"></i>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "driverCard",

  props: ["driver"]
};
</script>
