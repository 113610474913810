<template>
  <div class="flex-row-fluid d-flex flex-column ml-lg-8">
    <div class="card card-custom gutter-b">
      <!--begin::Body-->
      <div class="card-body d-flex align-items-center justify-content-between flex-wrap py-3">
        <!--begin::Info-->
        <div class="d-flex align-items-center mr-2 py-2">
          <!--begin::Title-->
          <h3 class="font-weight-bold mb-0 mr-10">Driver</h3>
          <!--end::Title-->
          <div class="input-icon">
            <input type="text" class="form-control" placeholder="Search..." v-model="search" />
            <span>
              <i class="flaticon2-search-1 text-muted"></i>
            </span>
          </div>
        </div>
        <!--end::Info-->
        <!--begin::Users-->
        <router-link :to="{ name: 'CreateDriver' }" tag="button" class="btn btn-primary font-weight-bolder">
          <span class="svg-icon svg-icon-md">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
            <inline-svg src="/media/svg/icons/Design/Flatten.svg"></inline-svg> </span
          >New driver</router-link
        >
      </div>
      <!--end::Body-->
    </div>

    <div v-if="paginatedData.length > 0">
      <transition-group name="list" tag="div" class="row">
        <div class="col-sm-6 col-lg-6 col-xl-3" v-for="driver in paginatedData" :key="driver.id">
          <!--begin::Card-->
          <driver-card :driver="driver" @delete-driver="toggleDriverStatus" />
          <!--end::Card-->
        </div>
      </transition-group>

      <pagination v-model="pagination" />
    </div>

    <no-data v-else></no-data>
  </div>
</template>

<script>
import { getAllDriver, lockDriver, unlockDriver } from "@/api/driver.api";

import Pagination from "@/components/general/Pagination";
import DriverCard from "./components/DriverCard";

import { ref, reactive, onMounted, computed } from "@vue/composition-api";
import Swal from "sweetalert2";

export default {
  name: "listDriver",
  components: { Pagination, DriverCard },

  setup() {
    let list = ref([]);
    const search = ref("");

    onMounted(() => refresh());

    const refresh = async () => {
      const response = await getAllDriver();
      list.value = response.data;
    };

    const pagination = reactive({
      currentPage: 1,
      totalRows: computed(() => list.value.length),
      showing: computed(() => paginatedData.value.length),
      itemInPage: 10
    });

    const paginatedData = computed(() => {
      const searchTerm = search.value.toLowerCase();
      let filteredData = list.value.filter(driver => {
        for (const key of Object.keys(driver)) {
          const val = driver[key] != null ? driver[key].toString().toLowerCase() : "";
          if (val.includes(searchTerm)) {
            return true;
          }
        }
        return false;
      });

      let index = (pagination.currentPage - 1) * pagination.itemInPage;
      return filteredData.slice(index, index + pagination.itemInPage);
    });

    const toggleDriverStatus = (id, status) => {
      if (status == "ACTIVE") {
        Swal.fire({
          title: "Confirm deactivate?",
          text: "You may re-activate this driver later ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then(async result => {
          if (result.isConfirmed) {
            await lockDriver(id);
            refresh();
            Swal.fire("Lock!", "This driver have been deactivated", "success");
          }
        });
      } else {
        Swal.fire({
          title: "Confirm Activate?",
          text: "You may de-activate this driver later ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then(async result => {
          if (result.isConfirmed) {
            await unlockDriver(id);
            refresh();
            Swal.fire("Unlock!", "This driver have been activated", "success");
          }
        });
      }
    };

    return { search, pagination, paginatedData, toggleDriverStatus };
  }
};
</script>
