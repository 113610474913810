<template>
  <!--begin::Pagination-->
  <div class="card card-custom">
    <div class="card-body py-7">
      <!--begin::Pagination-->
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <b-pagination
          v-model="value.currentPage"
          :total-rows="value.totalRows"
          :per-page="value.itemInPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          limit="7"
        ></b-pagination>
        <div class="d-flex align-items-center">
          <b-form-select
            class="text-primary font-weight-bold mr-4 border-0 bg-light-primary"
            style="width: 75px;"
            v-model="value.itemInPage"
          >
            <option :value="10">10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="value.totalRows">All</option>
          </b-form-select>
          <span class="text-muted">Displaying {{ value.showing }} of {{ value.totalRows }} records</span>
        </div>
      </div>
      <!--end:: Pagination-->
    </div>
  </div>
  <!--end::Pagination-->
</template>

<script>
export default {
  name: "pagination",

  props: ["value"]
};
</script>
